<template>
    <div style="position: relative;">
        <Toolbar class="header">
            <template #start>
                <a href="/">
                    <img src='/amazon-logo.jpg' />
                </a>
            </template>

            <template #end>
                <div v-if="currentUser != ''">
                    <span><vue-gravatar :email="currentUser" :size="150" /></span>
                    <router-link to="/login">
                        <span class="pi pi-user ml-2 mr-3" title="Users"></span>
                    </router-link>
                </div>
            </template>
        </Toolbar>

        <div style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);">
            <img src="@/assets/Jamii-Weight-Logo.png" alt="Image" height="75">
        </div>
    </div>

    <div class="wrapper">
        <div class="mt-2">
            <router-view />
        </div>
        <div class="mt-2 text-center bgwhite">
            <img src="/BRS2-logo.jpg" alt="Image" width="150">
        </div>
    </div>
</template>

<script setup>
    import { VueGravatar } from "vue3-gravatar";
    import Toolbar from 'primevue/toolbar';
    import { useRouter, useRoute } from 'vue-router';

    const router = useRouter();
    const route = useRoute();   
</script>



<style lang="scss">

    body {
        margin: 0;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        background-color: var(--surface-ground);
        font-family: var(--font-family);
        font-weight: 400;
        color: var(--text-color);
        -webkit-font-smoothing: antialiased;
    }

    .wrapper {
        padding: 5px 20px;
    }

    .tools {
        font-size: 40px;
        padding: 5px 20px;
    }

    .p-toolbar .pi {
        font-size: 1em;
    }

    .p-toolbar {
        padding: 0.8rem;
        font-size: 1.5em !important;
    }

    .p-toolbar a:link,
    .p-toolbar a:visited {
        color: var(--text-color);
        text-decoration: none;
    }

    .avatar {
        width: 30px;
        height: 30px;
        margin-bottom: -3px;
    }
    .p-dialog {
        overflow-x: auto;
        white-space: nowrap;
    }
    .header{
        background: #fff;
        margin: 0 20px;
    }
    .bgwhite{
        background: #fff;
    }
</style>
