import { defineStore } from "pinia";
import { Configuration, CompanyApi, Company } from "@/generated/";
import { ref } from "vue";
import { API_URL } from "@/config";
import { handleError } from "@/utils/errors";

export const useCompanyStore = defineStore("company", () => {
  const apiConfig: Configuration = {
    basePath: API_URL,
    accessToken: window.localStorage.getItem("auth")?.toString(),
    isJsonMime: function (mime: string): boolean {
      throw new Error("Function not implemented.");
    }
  };

  const api = new CompanyApi(apiConfig);
  const companies = ref([] as Company[]);
  const loading = ref(false);

  async function getCompanies(page:number, perPage:number) {
    loading.value = true;
    return await api.companyGetPage(page, perPage).then((response) => {
      if (response.data.success) {
        companies.value = response.data.content!;
        loading.value = false;
        return Promise.resolve(response.data);
      } else {
        //handleError(response.data.message!);
        return Promise.reject(response.data);
      }
    }).catch(e => {
      
      console.log("error")
    });
  }


  return {
    getCompanies,
    companies,
    loading
  }
})
