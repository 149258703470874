import axios, { AxiosError } from "axios";
import { API_URL } from "./urls";
import { setAuthorizationHeader } from "@/utils";
import { handleError } from "@/utils/errors";
import { useAppStore } from "@/stores/app.store";

export const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

const handleAxiosError = (error: AxiosError): Promise<never> => {
  console.log("Handling error")
  const appStore = useAppStore();
  appStore.stopLoading();
  handleError(error.message);
  return Promise.reject(error);
};
axiosInstance.interceptors.request.use((config) => {
  const appStore = useAppStore();
  appStore.startLoading();
  setAuthorizationHeader();
  return config;
}, handleAxiosError);

axiosInstance.interceptors.response.use((response) => {
  const appStore = useAppStore();
  appStore.stopLoading();
  return response;
}, handleAxiosError);
