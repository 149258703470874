import { createApp, markRaw } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from "pinia";
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import { setAuthorizationHeader } from "./utils";
import { HubConnectionBuilder, LogLevel, HttpTransportType } from '@microsoft/signalr'

import "primeflex/primeflex.css"
import 'primevue/resources/themes/lara-light-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';


const Vue = createApp(App);
const pinia = createPinia();

const connection = new HubConnectionBuilder()
    .withUrl("https://app2.jamiitrack.com/nodehub", {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
    })
    .build();

async function start() {
    connection.start().then((x) => {
        console.log("connected", connection)
    });
}
connection.onclose(async () => {
    await start();
});
start();

const ComponentContext = require.context('primevue/', true, /\.vue$/i, 'lazy');

pinia.use(({ store }) => {
    store.router = markRaw(router);
});

Vue.use(pinia)
    .use(router)
    .use(PrimeVue, {ripple: true})
    .provide("signalr", connection)
    .use(ToastService);

ComponentContext.keys().forEach((componentFilePath) => {
    const componentName = componentFilePath.split('/')?.pop()?.split('.')[0];
    if (componentName != undefined) Vue.component(componentName, () => ComponentContext(componentFilePath));
});

Vue.mount('#app')

setAuthorizationHeader();
