import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import { useAuthStore } from '@/stores';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue'),
    meta: {
      requiresAuth: false
    }
  }
  ,
  {
    path: '/company/:id',
    name: 'company',
    component: () => import('../views/CompanyView.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/site/:id',
    name: 'site',
    component: () => import('../views/SiteView.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/gateway/:id',
    name: 'gateway',
    component: () => import('../views/GatewayView.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/sites/:siteid/nodes/:nodeid",
    name: "node",
    component: () => import("../views/NodeView.vue"),
    meta: {
      requiresAuth: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  const store = useAuthStore();
    
    if (to.meta.requiresAuth == true) {
        if (!store.isLoggedIn) {
          return next({ path: "/login", query: { redirectFrom: to.fullPath } });
        } 
    }
    return next(); 
});

export default router
