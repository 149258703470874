<script lang="ts" setup>
    import { useRouter } from 'vue-router'
    import { useCompanyStore } from '@/stores'
    import DataTable  from 'primevue/datatable'
    import Column from 'primevue/column'
    import Button from 'primevue/button'

    const router = useRouter();
    const store = useCompanyStore();

    const refresh = () => {
      store.getCompanies(0, 20);
    }

    const rowClick = (event: any) => {
      router.push({ name: 'company', params: { id: event.data.companyId } })
    }
    const newCompany = () => {
      console.log("testy");
    }

    refresh();

</script>

<template>
    <DataTable  :value="store.companies" :paginator="true" :rows="10" :loading="store.loading" @row-click="rowClick($event)" responsiveLayout="scroll">
      <template #header>
        <div class="grid grid-nogutter">
          <div class="col-6" style="text-align: left">
            <div class="col-6" style="text-align: left">
              Companies
            </div>
          </div>
          <div class="col-6" style="text-align: right">
            <button class="p-panel-header-icon p-link mr-2" @click="newCompany()">
              <span class="pi pi-plus"></span>
            </button>
          </div>
        </div>
      </template>
      <template #empty>
        No companies found
      </template>
      <template #loading>
        Loading companies. Please wait.
      </template>
      <Column field="name" header="Name"></Column>

    </DataTable>
</template>