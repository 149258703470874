import { isEmpty } from "./objects";
import { useAuthStore } from "@/stores/auth.store";

export enum AuthGuardType {
  LOGIN = "login",
  UNAUTHORIZED = "unauthorized",
}

export function authGuard(type: AuthGuardType) {
  const authStore = useAuthStore();
  if (authStore.isTokenValid) {
    return type === AuthGuardType.LOGIN ? { path: "/" } : true;
  }
  authStore.logout();
  return type === AuthGuardType.LOGIN ? true : { path: "/" };
}

export function isTokenExpired(decodedToken: any) {
  return decodedToken.exp != null && decodedToken.exp < Date.now() / 1000;
}

export function isTokenNullOrEmpty(decodedToken: any) {
  return decodedToken == null || isEmpty(decodedToken);
}
