import { axiosInstance, LOCAL_STORAGE_TOKEN } from "@/config";

export function setAuthorizationHeader() {
  const token = localStorage.getItem(LOCAL_STORAGE_TOKEN);
  if (token != "" && token != null) {
    axiosInstance.defaults.headers.common["Authorization"] = "Bearer " + token;
    return;
  }
  delete axiosInstance.defaults.headers.common["Authorization"];
}
