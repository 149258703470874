export const API_URL = "https://app2.jamiitrack.com";
export const PAGE_URLS = {
    HOME: "/",
    SET_PASSWORD: "/set-password",
    RESET_PASSWORD: "/reset-password",
    FORGOT_PASSWORD: "/forgot-password",
    REGISTER: "/register",
    USERS: "/users",
    SETTINGS: "/settings",
    SITES: "/sites",
    SITE: "/site/:id",
    EDITSITE: "/editsite/:id",
    SERVERS: "/servers",
    SERVER: "/server/:id",
    PROVIDERS: "/providers",
    PROVIDER: "/provider/:id",
    ARTICLES: "/articles",
    ARTICLE: "/article/:id/:edit?",
    CLIENTS: "/clients",
    CLIENT: "/client/:id",
  };
  