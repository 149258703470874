import { defineStore } from "pinia";
import { axiosInstance } from "@/config/axios";
import { Configuration, TokenApi, User } from "@/generated/";
import { computed, ref } from "vue";
import { API_URL } from "@/config";
import {jwtDecode} from "jwt-decode";
import { isTokenExpired, isTokenNullOrEmpty } from "@/utils/guards";
import { isEmpty } from "lodash";
import { handleError } from "@/utils/errors";

export const useAuthStore = defineStore("auth", () => {
  const apiConfig: Configuration = {
    basePath: API_URL,
    isJsonMime: function (mime: string): boolean {
      throw new Error("Function not implemented.");
    }
  };

  const tokenApi = new TokenApi(apiConfig);
  const token = ref(window.localStorage.getItem("auth"));
  const decodedToken = ref({} as any);
  const currentUser = ref({} as User);
  const userId = computed(() => currentUser.value.userId);
  const userRole = computed(() => currentUser.value.roles);
   // const isAdmin = computed(() => currentUser.value?.roles?.indexOf("Admin") > -1 ?? false);
  const availableCompanies = computed(() => currentUser.value.companies);

  const isLoggedIn = computed(() => isTokenValid.value);
  const isTokenValid = computed(() => {
      if (!token.value) {
        return false;
      }
  
      try {
        if (isEmpty(decodedToken.value)) {
          decodedToken.value = jwtDecode(token.value);
        }
  
        return (
          decodedToken.value &&
          !isTokenNullOrEmpty(decodedToken.value) &&
          !isTokenExpired(decodedToken.value)
        );
      } catch (error) {
        console.error("Error decoding token: ", error);
        return false;
      }
  });

  async function login(email: string, password: string) {
    return await tokenApi.authorize(email, password).then((response) => {
      if (response.data.success) {
        axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${response.data.content?.access_token}`;
        window.localStorage.setItem("auth", response.data.content?.access_token ?? "");
        token.value = response.data.content?.access_token ?? "";
        decodedToken.value = jwtDecode(response.data.content?.access_token ?? "");
       // isLoggedIn.value = true;
        return Promise.resolve(response.data);
      } else {
        handleError(response.data.message!);
        return Promise.reject(response.data);
      }
    });
  }

  async function logout() {
    window.localStorage.removeItem("auth");
    token.value = null;
    currentUser.value = {} as User;
   // isLoggedIn.value = false;
  }

  return {
    login,
    logout,

    userId,
    isLoggedIn,
    isTokenValid,
    userRole
  }
})
