import type { AxiosError, AxiosResponse } from "axios";
import { useToast } from 'primevue/usetoast';

export function handleError(error: string) {
  const message = error;
  const toast = useToast();
  toast.add({
    closable: true,
    severity: "error",
    detail: message,
    summary: "Error Occured"
  })
}

