import { defineStore } from "pinia";
import { Configuration, UserApi, User } from "@/generated/";
import { API_URL } from "@/config";

export const useUserStore = defineStore("user", () => {
  const apiConfig: Configuration = {
    basePath: API_URL,
    isJsonMime: function (mime: string): boolean {
      throw new Error("Function not implemented.");
    }
  };

  const tokenApi = new UserApi(apiConfig);
  
  return {
    
  }
})
