<script setup lang="ts">
    import {useAuthStore} from '@/stores/auth.store'
    import { useRouter } from 'vue-router'
    import CompanyTable from "@/components/company/CompanyTable.vue"

    const router = useRouter();
    const store = useAuthStore();

    if (!store.isLoggedIn) {
        console.log("Not logged in");
        router.push({ name: "login" });
    } 
</script>

<template>
    <div class="home">
        <h1>Home</h1> 
    </div>
    <CompanyTable />
</template>