import { defineStore } from "pinia";
import { ref } from "vue";

export const useAppStore = defineStore("app", () => {
  const loading = ref(false);
  const hideLoading = ref(false);
  const loadingCount = ref(0);
  const minimumLoadingTime = 0;

  const startLoading = () => {
    loading.value = true;
    loadingCount.value++;
  };

  const stopLoading = () => {
    loadingCount.value--;
    if (loadingCount.value > 0) return;

    setTimeout(() => {
      if (loadingCount.value > 0) return;
      loading.value = false;
    }, minimumLoadingTime);
  };

  return {
    loading,
    loadingCount,
    hideLoading,

    startLoading,
    stopLoading,
  };
});
